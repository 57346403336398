.container {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: pink;
    z-index: 3;
    align-items: center;
}

.container .backgroundImage {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
}
