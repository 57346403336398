@import '~antd/dist/antd.css';
@font-face {
  font-family: "Obviously";
  src: local("Obviously"),
   url("./fonts/ObviouslyRegular.otf") format("truetype");
}

@font-face {
  font-family: "ObviouslyBold";
  src: local("ObviouslyBold"),
   url("./fonts/ObviouslyBold.otf") format("truetype");
}

@font-face {
  font-family: "ObviouslyItalic";
  src: local("ObviouslyItalic"),
   url("./fonts/ObviouslyItalic.otf") format("truetype");
}

@font-face {
  font-family: "ObviouslyLight";
  src: local("ObviouslyLight"),
   url("./fonts/ObviouslyLight.otf") format("truetype");
}

@font-face {
  font-family: "Obviously550";
  src: local("Obviously550"),
   url("./fonts/Obviously550.otf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}

.ant-collapse-borderless > .ant-collapse-item:last-child, .ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 8px;
}

.ant-slider-step {
  margin: 3px 0;
  height: 10px;
}

.ant-slider-dot {
  width: 10px;
  height: 10px;
  top: 0;
  border: none;
}

.ant-slider-rail {
  height: 16px;
  background: linear-gradient(180deg, #D6D6D6 0%, #B3B3B3 100%);
  mix-blend-mode: normal;
  box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.4);
  border-radius: 99px;
}

.ant-slider-disabled {
  cursor: default;
}

.ant-slider-disabled .ant-slider-mark-text, .ant-slider-disabled .ant-slider-dot {
  cursor: default !important;
}

.ant-slider-disabled .ant-slider-handle, .ant-slider-disabled .ant-slider-dot {
  cursor: default !important;
}

.ant-drawer-right.ant-drawer-open{
  transform: translateX(0) !important;
}